<template>
  <section id="top">
    <div
      class="banner"
      :style="{ backgroundImage: 'url(' + img_url + encodeURIComponent(banner.image).replace('%2F', '/') + ')' }"
    >
      <div class="content events">
        <h4 v-html="banner.title"></h4>
        <p v-html="banner.description"></p>
      </div>
    </div>
    <div class="cards">
      <ul>
        <li v-for="(event, i) in dados" :key="i">
          <EventCard :events="dados[i]" />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";
import EventCard from "@/components/Event-card.vue";

export default {
  mixins: [computedMixins, methodsMixins],

  components: {
    EventCard,
  },

  data() {
    return {
      // banner: {
      //   title: "News and Events",
      //   description:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id lacinia sapien. Vivamus eros mi, vulputate et justo sed, porttitor porttitor turpis. Aenean nec ipsum justo.",
      // },
      items: [],
      slideItems: [],

      // initializeEP: "events/list-all",

      // slidesEP: "events/banners/bo-list-all"
    };
  },
  computed: {
    initializeEP() {
      let temp = "events/"+this.version+"list-all"
      return temp
    },
    slidesEP() {
      let temp = "events/banners/"+this.version+"list-all"
      return temp
    },
    banner() {
      let temp = {};
      this.n

      for (let item of this.slideItems) {
        temp = item
        if(typeof temp.content != 'undefined') {
          let content = JSON.parse(temp.content)
          let lang = this.pageLanguage.toLowerCase()
                    content = content[lang]

          for(let k in content) {
            temp[k] = content[k]
          }

          temp.content = content
        }
      }

      return temp;
    },
  },
  created() {
    this.initializeSlides(this.slidesEP)
    this.initialize(this.initializeEP)
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.banner {
  height: 500px;
  @include responsive(0, 512px){
    height: 400px;
  }
  .content {
    p {
      color: white !important;
      p {
        color: white !important;
      }
      span {
        color: white !important;
      }
    }
  }
}
.cards {
  margin: 50px 0;
  ul {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto !important;
    li {
      display: inline-block;
      vertical-align: top;
      margin: 20px;
    }
  }
}
</style>