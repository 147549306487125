<template>
  <div>
    <v-card
      :to="'/event/' + events.id + '/' + events.title.trim().replaceAll(' ', '-')"
      class="mx-auto my-5 event card"
      v-if="events.category == 'event' || events.category == 'Event'"
    >
      <div
        class="img-container"
        :style="{ backgroundImage: 'url(' + img_url + encodeURIComponent(events.thumbnail).replace('%2F', '/') + ')' }"
      ></div>
      <v-card-text>
        <p class="category">{{ events.category }}</p>
        <h5 v-html="events.title"></h5>
        <p class="date">{{ events.date }} | {{ events.start_hour.slice(0, 5) }}h</p>
        <p class="location"><span class="icon-icon-location"></span> {{ events.location }}</p>
      </v-card-text>

      <v-card-actions>
        <a class="button-fill">{{ content.events.btn_card }}</a>
      </v-card-actions>
    </v-card>
    <v-card
      :to="'/event/' + events.id + '/' + events.title.trim().replaceAll(' ', '-')"
      class="mx-auto my-5 event card"
      v-else
    >
      <div
        class="img-container"
        :style="{ backgroundImage: 'url(' + img_url + encodeURIComponent(events.thumbnail).replace('%2F', '/') + ')' }"
      ></div>
      <v-card-text>
        <p class="category">{{ events.category }}</p>
        <h5 v-html="events.title"></h5>
        <p class="date">{{ events.subtitle }}</p>
      </v-card-text>

      <v-card-actions>
        <a class="button-fill">{{ content.events.btn_card }}</a>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

export default {
  mixins: [computedMixins, methodsMixins],

  props: ["events"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.card {
  width: 330px;
  height: 600px;
  background-color: $light-greyI;
  text-align: center;
  @include responsive(0, 512px){
    width: 100%;
  }
  .img-container {
    width: 100%;
    height: 260px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  h5 {
      min-height: 80px;
  }
  .category{
      text-transform: uppercase;
      font-size:12px;
      letter-spacing: 2px;
      margin-top: 10px;
  }
  .date{
      font-weight: 300;
      text-transform: uppercase;
      font-size: 15px;
  }
  .location{
      text-transform: uppercase;
      font-size: 14px;
      margin-top: 15px;
      span{
          font-family: 'icomoon';
          font-size:18px;
          color: black;
      }
      .icon-icon-location:before {
  content: "\e906";
}
  }
  a{
      position: absolute;
      bottom: 50px;
      left:50%;
      transform: translateX(-50%);
      
  }
}

.v-card{
    &:hover{
        box-shadow: none!important;
    }
}
</style>